import { MAGENTO_ENDPOINT } from '~/config/constants'
import { GraphQLClient } from 'graphql-request'
import { QueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'
import { CostumeSession } from '~/hooks/useSession'
import { getStoreCode } from '~/lib/getStoreCode'

type IClientProps = {
  /**
   * @deprecated This type will be removed in the upcomming releases
   */
  usePost?: boolean
  preview?: boolean
  queryClient: QueryClient
  errorPolicy?: 'ignore' | 'all'
  locale?: string
  defaultToken?: string
}

export const createMagentoClient = (
  { usePost, queryClient, errorPolicy, locale, defaultToken }: IClientProps = {
    queryClient: new QueryClient(),
  },
) => {
  invariant(MAGENTO_ENDPOINT, 'No NEXT_PUBLIC_MAGENTO_URL env variable provided.')

  const token = defaultToken ?? queryClient?.getQueryData<CostumeSession>(['session'])?.user?.token
  const url = typeof window === 'undefined' ? MAGENTO_ENDPOINT : '/magento'
  const headers: { [key: string]: string } = {}

  if (token) headers.Authorization = `Bearer ${token}`

  headers.Store = getStoreCode({ locale }) ?? 'benuta_de'

  return new GraphQLClient(url, {
    jsonSerializer: JSON,
    headers: () => headers,
    errorPolicy,
  })
}
