import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { createMagentoClient } from '~/graphql/magentoClient'
import { useCustomAttributeMetadataQuery } from '@magentoTypes'
import { findAttributeLabel } from '~/lib/findAttributeLabel'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'

type LabelInput = {
  attributeCode: string
  attributeValue: string | number
}

export const useProductAttributes = () => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })
  const { data } = useCustomAttributeMetadataQuery(magentoClient)
  const findLabel = ({ attributeCode, attributeValue }: LabelInput) => {
    return findAttributeLabel({ attributeCode, attributeValue, data })
  }
  return { findLabel }
}
