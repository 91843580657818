import { z } from 'zod'

// errors that need a new cartId
export const resettableErrors = ['graphql-no-such-entity', 'graphql-authorization']
const returnSchema = z.object({
  message: z.string(),
  extensions: z.object({ category: z.string() }),
})

export function parseMagentoGraphqlError(input: Error) {
  try {
    const parsedError = JSON.parse(JSON.stringify(input))?.response?.errors?.[0]

    return returnSchema.parse(parsedError)
  } catch (e) {
    console.error(e)
  }
}
