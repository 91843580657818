import { GraphQLClient } from 'graphql-request'
import invariant from 'tiny-invariant'

type IClientProps = {
  preview: boolean | undefined
  errorPolicy?: 'ignore' | 'all'
}

export const createGraphQLClient = ({ preview, errorPolicy }: IClientProps) => {
  invariant(
    process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    'No NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN env variable provided.',
  )
  invariant(
    process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    'No NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN env variable provided.',
  )
  invariant(
    process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    'No NEXT_PUBLIC_CONTENTFUL_SPACE_ID env variable provided.',
  )

  const endpoint = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENV}`

  return new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Bearer ${
        preview
          ? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN
          : process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
      }`,
    },
    // errorPolicy: process.env.NODE_ENV === 'production' ? 'ignore' : 'none',
    errorPolicy: 'ignore',
  })
}
