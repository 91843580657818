import { CustomAttributeMetadataQuery } from '@magentoTypes'

type LabelInput = {
  attributeCode: string
  attributeValue: string | number
  data: CustomAttributeMetadataQuery | undefined
}
export const findAttributeLabel = ({ attributeCode, attributeValue, data }: LabelInput) => {
  if (!attributeCode || !attributeValue || !data) return ''
  const attributeValueStr = attributeValue?.toString()
  const attribute = data?.customAttributeMetadata?.items?.find(
    (item) => item?.attribute_code === attributeCode,
  )
  const labels = attributeValueStr
    ?.split(',')
    ?.map((value) => attribute?.attribute_options?.find((item) => item?.value === value)?.label)
  return labels.join(', ')
}
