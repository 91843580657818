import { Scalars, RteRteLinks } from '@contentfulTypes'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Image } from '@next/image'
import { Link } from 'elements/Link/Link'
import styles from './RTEParser.module.css'
import clsx from 'clsx'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'

type Content = {
  className?: string
  rteJSONContent: Scalars['JSON']
  links?: RteRteLinks
}

export const RTEParser = ({ className, rteJSONContent, links }: Content) => {
  const renderOptions = (links: RteRteLinks, rteJSONContent?: any) => {
    const assetMap = new Map()
    if (links?.assets?.block) {
      for (const asset of links.assets.block) {
        assetMap.set(asset?.sys.id, asset)
      }
    }
    const entryMap = new Map()
    if (links?.entries?.block) {
      for (const entry of links.entries.block) {
        entryMap.set(entry?.sys.id, entry)
      }
    }

    if (links?.entries?.inline) {
      for (const entry of links?.entries?.inline) {
        entryMap.set(entry?.sys.id, entry)
      }
    }
    if (links?.entries?.hyperlink) {
      for (const entry of links.entries.hyperlink) {
        entryMap.set(entry?.sys.id, entry)
      }
    }

    return {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
          const entry = entryMap.get(node.data.target.sys.id)
          if (entry?.__typename === 'Cta') {
            return <ContentfulButton className="my-4" {...entry} />
          } else {
            return (
              <Link href={entry?.slug} title={entry?.title}>
                {entry?.title}
              </Link>
            )
          }
        },
        [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
          const entry = entryMap.get(node.data.target.sys.id)

          if (entry?.__typename === 'Cta') {
            return <ContentfulButton {...entry} />
          } else {
            return (
              <Link href={entry?.slug} title={entry?.title}>
                {children}
              </Link>
            )
          }
        },
        [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
          const entry = entryMap.get(node.data.target.sys.id)

          if (entry?.__typename === 'Cta') {
            return <ContentfulButton {...entry} />
          } else {
            return (
              <Link href={entry?.slug} title={entry?.title}>
                {entry?.title}
              </Link>
            )
          }
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
          const asset = assetMap.get(node.data.target.sys.id)

          return (
            <div className="my-4 mx-auto flex max-w-full justify-center">
              <Image
                src={asset.url}
                alt={asset.description}
                width={asset.width as number}
                height={asset.height as number}
              />
            </div>
          )
        },
      },
      // * Adds line break to RTE code, read here https://www.smartercontent.info/post/how-to-add-line-breaks-to-any-text-field-in-contentful
      renderText: (text: string) => {
        return text?.split('\n').reduce((children: any, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
      },
    }
  }

  return (
    <div className={clsx(className && className, styles.rte)}>
      {links
        ? documentToReactComponents(rteJSONContent, renderOptions(links, rteJSONContent))
        : documentToReactComponents(rteJSONContent, renderOptions(rteJSONContent))}
    </div>
  )
}
