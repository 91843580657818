import { ImageProps } from 'next/image'
import { CSSProperties, useMemo } from 'react'
import { hasQueryString } from '~/lib/hasQueryString'
import { Props as BaseImageProps } from './Image'

interface Props extends BaseImageProps {
  src: ImageProps['src']
}

const defaultDeviceSizes = { '1x': 1, '2x': 2 }
const defaultDeviceSizesFull = {
  '256w': 256,
  '384w': 384,
  '640w': 640,
  '750w': 750,
  '1080w': 1080,
  '1200w': 1200,
  '1920w': 1920,
  '2048w': 2048,
  '3840w': 3840,
}

export const ImageOptimized = ({
  src,
  hasNoBlur,
  alt,
  quality = 70,
  fill,
  style,
  deviceSizes,
  ...rest
}: Omit<ImageProps, 'src'> & Props) => {
  const stringSrc = src.toString()

  const width = rest.width as number
  const resolvedDeviceSizes: Record<string, number> =
    deviceSizes || (width && width < 2000 ? defaultDeviceSizes : defaultDeviceSizesFull)

  const finalSrc = stringSrc.includes('b2b.benuta.com')
    ? stringSrc.replace(/\/cache\/[a-f0-9]+\//, '/')
    : stringSrc.replace('downloads.ctfassets.net', 'images.ctfassets.net')

  const getOptimizedSrc = useMemo(() => {
    const sizes = Object.values(resolvedDeviceSizes)
    const size = sizes.pop() || 1
    const calculatedWidth = width && width < 2000 ? Math.floor(size * width) : size
    const srcFilter = stringSrc.includes('b2b.benuta.com')
      ? `width=${calculatedWidth}&quality=${quality}`
      : `w=${calculatedWidth}&q=${quality}&fm=webp`
    return hasQueryString(finalSrc) ? `${finalSrc}&${srcFilter}` : `${finalSrc}?${srcFilter}`
  }, [resolvedDeviceSizes, width, quality, stringSrc, finalSrc])

  const optimizedSrcset = useMemo(() => {
    return Object.keys(resolvedDeviceSizes)
      .map((key) => {
        const calculatedWidth =
          width && width < 2000
            ? Math.floor(resolvedDeviceSizes[key] * width)
            : resolvedDeviceSizes[key]
        const srcsetFilter = stringSrc.includes('b2b.benuta.com')
          ? `width=${calculatedWidth}&quality=${quality}`
          : `w=${calculatedWidth}&q=${quality}&fm=webp`
        return hasQueryString(finalSrc)
          ? `${finalSrc}&${srcsetFilter} ${key}`
          : `${finalSrc}?${srcsetFilter} ${key}`
      })
      .join(', ')
  }, [resolvedDeviceSizes, width, quality, stringSrc, finalSrc])

  const optimizedStyle: CSSProperties = (
    rest.width
      ? style
      : {
          position: 'absolute',
          height: '100%',
          width: '100%',
          inset: '0px',
          color: 'transparent',
          ...style,
        }
  ) as CSSProperties

  return (
    <img
      decoding="async"
      loading={rest.loading}
      src={getOptimizedSrc}
      srcSet={optimizedSrcset}
      alt={alt || ''}
      style={optimizedStyle}
      placeholder={hasNoBlur ? 'empty' : 'blur'}
      {
        // this prevents react from throwing error about invalid attribute
        ...{
          ...rest,
          priority: null,
        }
      }
    />
  )
}
