import { CartPageQuery, CartQuery, ConfigurableCartItem } from '@magentoTypes'
import merge from 'deepmerge'
import { useRouter } from 'next/router'
import TagManager, { DataLayerArgs } from 'react-gtm-module'
import { useProductAttributes } from './useProductAttributes'

export enum DataLayerEnum {
  PAGE,
  EVENT,
}

export const useGTM = () => {
  const router = useRouter()
  const { findLabel } = useProductAttributes()

  const addToDataLayer = (dataLayerArgs: DataLayerArgs) => {
    let args: DataLayerArgs = {}

    const dataLayer = (window as any)?.dataLayer || []
    const isConsentsInitiated =
      dataLayer.findIndex((d: any) => d?.event == 'consents_initialized') != -1
    const isNextjsInitiated = dataLayer.findIndex((d: any) => d?.event == 'nextjs_initiated') != -1
    if (isConsentsInitiated && !isNextjsInitiated) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'nextjs_initiated',
        },
      })

      args = {
        dataLayer: {
          event: 'page_view',
        },
      }
    }

    TagManager.dataLayer({ dataLayer: { ecommerce: null } })
    TagManager.dataLayer(merge.all([args, dataLayerArgs]))
  }

  const ecommerceData = (data?: CartQuery | CartPageQuery) => {
    return {
      value: data?.cart?.prices?.grand_total?.value,
      coupon: data?.cart?.applied_coupons?.map((coupon) => coupon?.code).join(', ') || '',
      items: (data?.cart?.items as ConfigurableCartItem[])?.map((item) => ({
        item_id: item?.configured_variant?.sku,
        item_ean: item?.configured_variant?.eannummer,
        item_name: item?.product.name,
        item_brand: findLabel({
          attributeCode: 'manufacturer',
          attributeValue: item?.product.manufacturer as number,
        }),
        item_variant: item.configurable_options[0]?.value_label || '',
        item_category: '',
        price: item?.prices?.row_total_including_tax.value,
        quantity: item?.quantity,
        coupon: '',
        discount: 0,
      })),
    }
  }

  function dataLayer(type: DataLayerEnum.PAGE, ecommPagetype: string): void
  function dataLayer(
    type: DataLayerEnum.PAGE,
    ecommPagetype: string,
    dataLayerArgs: DataLayerArgs,
  ): void
  function dataLayer(type: DataLayerEnum.EVENT, eventName: string): void
  function dataLayer(
    type: DataLayerEnum.EVENT,
    eventName: string,
    dataLayerArgs: DataLayerArgs,
  ): void
  function dataLayer(type: DataLayerEnum, value: string, dataLayerArgs: DataLayerArgs = {}) {
    let args: DataLayerArgs = {}

    switch (type) {
      case DataLayerEnum.PAGE:
        args = {
          dataLayer: {
            ecomm_pagetype: value,
            page_path: router.asPath,
            title: typeof document !== 'undefined' ? document?.title : '',
            page_url: typeof document !== 'undefined' ? (document as any)?.current_url : '',
            referrer: typeof document !== 'undefined' ? (document as any)?.previous_url : '',
          },
        }
        break
      default:
        args = {
          dataLayer: {
            event: value,
          },
        }
        break
    }

    //TagManager.dataLayer({dataLayer: {ecommerce: null}})
    //TagManager.dataLayer(merge.all([args, dataLayerArgs]))
    addToDataLayer(merge.all([args, dataLayerArgs]))
  }

  return { dataLayer, ecommerceData }
}
