import { DEFAULT_LOCALE } from '~/config/constants'

type StoreCodeProps = {
  locale: string | undefined
}

export const getStoreCode = ({ locale }: StoreCodeProps) => {
  const swissLocale = locale || DEFAULT_LOCALE
  const storeCode =
    process.env.NEXT_PUBLIC_COUNTRY_CODE === 'CH'
      ? `benuta_ch${swissLocale.slice(0, 2)}`
      : process.env.NEXT_PUBLIC_STORE_CODE
  return storeCode
}
